import { useMemo } from 'react'

import { formatPrice } from '@commerce/product/use-price'
import { Picture } from '@components/ui'
import { useRouter } from 'next/router'
import s from './Discount.module.css'
import { cn } from '@components/helper'

// 折扣角标
const Discount = ({
  data,
  discountPriceNumber = null,
  product,
  cns = {},
  className = '',
  save = false,
  customDiscount = null,
  hidebgImg = false,
}) => {
  const { locale } = useRouter()
  // if (discountPriceNumber === 0) return null
  if (
    !discountPriceNumber &&
    (((!product?.discountValue || product?.discountValue <= 0) &&
      !product?.fakeDiscount) ||
      !product?.availableForSale)
  )
    return null

  const discount = useMemo(() => {
    if (customDiscount && customDiscount > 0)
      return formatPrice({
        amount: parseInt(customDiscount),
        locale,
        currencyCode: product?.currencyCode,
        maximumFractionDigits: 0,
      })
    if (discountPriceNumber && discountPriceNumber > 0)
      return formatPrice({
        amount: parseInt(discountPriceNumber),
        locale,
        currencyCode: product?.currencyCode,
        maximumFractionDigits: 0,
      })
    if (product?.fakeDiscount) return product?.fakeDiscount
    return ['fixed_amount', 'fix_discount_amount', 'fix_amount'].includes(
      product?.discountType
    )
      ? formatPrice({
          amount: parseInt(product.discountValue),
          locale,
          currencyCode: product?.currencyCode,
          maximumFractionDigits: 0,
        })
      : parseInt(product.discountValue) + '%'
  }, [discountPriceNumber, product, customDiscount])

  const defaultCouponUrl =
    'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/a.png?v=1679279186'
  const defaultMobileCouponUrl =
    'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/spring-sales_discount_0-767.png?v=1679280084'
  return (
    <div
      style={{
        top: data?.styles?.discountTop,
      }}
      className={cn(
        'absolute left-[30px] top-[-6px] z-[1] h-[72px] w-fit text-center font-bold text-white md:left-[16px] md:h-[50px]',
        cns?.discountWrap,
        className,
        'discountSetWrap'
      )}
    >
      {(data?.couponUrl || data?.couponImg || defaultCouponUrl) &&
      !hidebgImg ? (
        <Picture
          className={cn(s.discountImg, cns?.discountImg)}
          source={`${data.couponUrl || data?.couponImg || defaultCouponUrl}, ${
            data.mobileCouponUrl || data?.couponImg || defaultMobileCouponUrl
          } 767`}
        />
      ) : (
        <div className={cn(s.discountImg, cns?.discountImg)}></div>
      )}
      <div
        className={cn(
          'discount_save_box absolute left-[-3px] top-0 flex h-full w-full flex-col items-center justify-center',
          cns?.discount
        )}
      >
        {save && (
          <p
            className={cn(
              'discount_save text-[16px] md:text-[12px]',
              cns?.discountText
            )}
          >
            {data.save}
          </p>
        )}
        {discount && (
          <p
            className={cn(
              'discount_price text-[20px] md:text-[14px]',
              cns?.discountValue
            )}
          >
            {(['fr']?.includes(locale) ? '- ' : '') + discount}
          </p>
        )}
        {!save && (
          <p
            className={cn(
              'discount_off text-[16px] md:text-[12px]',
              cns?.discountText
            )}
          >
            {data?.off}
          </p>
        )}
      </div>
    </div>
  )
}

export default Discount
